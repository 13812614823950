@keyframes geolocate-expansion {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.base {
  position: relative;
  color: var(--colorTextBlackSoft);
}

.icon {
  transition: 0.3s ease opacity;
}

.inProgress:before,
.inProgress:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.inProgress:after {
  background-color: var(--colorBlack60);
  height: 2px;
  width: 2px;
}

.inProgress:before {
  width: 65%;
  height: 65%;
  transform-origin: center;
  background-color: var(--colorBlack30);
  opacity: 0;
}

.inProgress:before {
  animation-duration: 2s;
  animation-name: geolocate-expansion;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.inProgress .icon {
  opacity: 0;
}

.isActive {
  color: var(--colorGreenAccent);
}
