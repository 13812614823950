.isActive {
  position: relative;
  background-color: rgba(0, 98, 65, 0.1); /* colorGreenStarbucks */
  border: 2px solid var(--colorGreenStarbucks);
  border-radius: var(--space-2);
}

.overlayLink:focus-visible {
  /*
   * Replace outline with inset box shadow because cards have `overflow: hidden` which
   * prevents outline from displaying.
   *
   * The same visual could be accomplished with outline-offset, but browser support is spotty.
   */
  outline: none;
  box-shadow: inset 0 0 0 2px var(--colorHouseGreen);
  inset: var(--space-2);
  height: auto !important;
  width: auto !important;
  border-radius: var(--space-2);
}
