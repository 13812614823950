.card {
  min-height: 96px;
}

.cardCompleteContent {
  flex-direction: row !important;
  align-items: center;
  height: 100%;
  width: 100%;
}

@media (--breakpointLg) {
  .card {
    height: 120px;
  }
}
