.item {
  color: var(--colorTextBlackSoft);
  font-size: var(--filterItemFontSize);
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.icon {
  margin-right: 18px;
  position: relative;
  top: -1px;
  left: -2px;
}
