.container {
  text-decoration: none;
  padding: 12px 8px;
}

.checkInContainer {
  background: var(--colorWhite);
  border-radius: var(--cardBorderRadius);
  box-shadow: var(--cardBoxShadow);
}

.imageWrapper {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colorGreenLight);
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.closedBanner {
  background: var(--colorWhite);
  border-radius: 10px;
}

.closedBannerIcon {
  border-radius: 50%;
  color: var(--colorTextBlackSoft);
  padding: 10px;
}

.newLabel {
  background-color: var(--colorGreenStarbucks);
  color: var(--colorWhite);
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 11px;
  font-weight: bold;
  margin-left: 8px;
  margin-bottom: 2px;
  padding: 1px 4px;
}
