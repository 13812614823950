.links {
  flex-grow: 0;
  flex-shrink: 1;
}

.schedule {
  display: table;
  width: 100%;
  max-width: 350px;
}

.scheduleItem {
  display: table-row;
}

.scheduleItemHighlight {
  composes: scheduleItem;
  font-weight: 700;
}

.scheduleDay,
.scheduleHours {
  display: table-cell;
}

.scheduleHours {
  text-align: right;
}

.scheduleItem:nth-of-type(n + 2) .scheduleDay,
.scheduleItem:nth-of-type(n + 2) .scheduleHours {
  padding-top: 8px;
}

.featureListItem {
  border-bottom: 1px solid var(--colorBlack10);
}

.featureListItem:last-of-type {
  border-bottom: none;
}

@media (min-width: 420px) and (max-width: 599px),
  (--breakpointLg) and (--breakpointXxlMax) {
  /* let amenities lists break into two columns */
  .featureItem {
    display: inline-block;
    width: 50%;
    padding-right: 2%;
  }
  .featureItem:nth-of-type(-n + 2) {
    margin-top: 0;
  }
}

@media (min-width: 600px) and (--breakpointLgMax), (--breakpointXxl) {
  /* let amenities lists break into three columns */
  .featureItem {
    display: inline-block;
    width: 33%;
    padding-right: 3%;
  }
  .featureItem:nth-of-type(-n + 3) {
    margin-top: 0;
  }
}

.icon {
  margin-right: 6px;
  position: relative;
  top: -2px;
}
