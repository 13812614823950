@import '../root/vars.css';

.container {
  flex-grow: 1;
  flex-basis: var(--mapHeightMobile);
  position: relative;
  z-index: 1; /* below the locatorControls */
}

@media (--breakpointMd) {
  .container {
    top: 0;
  }
}

.map {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.buttonPosition {
  position: absolute;
  right: 0;
  bottom: 2rem;
}

@media (--breakpointMd) {
  .buttonPosition {
    bottom: 40px;
  }
}
