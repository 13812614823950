.loadingOverlay {
  width: 100%;
  height: 100%;
  background: var(--colorWhite);
  opacity: 0.7;
  position: absolute;
  z-index: 3;
  left: 0;
}

.svcFlex {
  width: 40px;
}

.svcImage {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.paymentInstrumentImage {
  box-shadow: none;
}

.fallbackImage {
  height: auto !important;
}

.earnRateLabel {
  pointer-events: none;
  right: 35px;
  top: 25px;
}
