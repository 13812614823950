.card {
  background-color: var(--cardBackgroundColor);
  border-radius: var(--cardBorderRadius);
  box-shadow: var(--cardBoxShadow);
  overflow: hidden;
}

.image {
  object-fit: cover;
}
