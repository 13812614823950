.dottedUnderline {
  background-image: -webkit-repeating-radial-gradient(
    center center,
    currentColor,
    currentColor 1px,
    transparent 1px,
    transparent 100%
  );
  background-repeat: repeat-x;
  background-position: 0 75%;
  background-size: 5px 5px;
  opacity: 0.3;
  min-height: calc(var(--textSize2) * 1.25);
}
