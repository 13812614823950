.base {
  max-width: 640px;
}

.cardNumber span {
  margin: 0 6px;
  font-size: 10px;
}

.successTitle {
  margin-top: calc(-1 * var(--space-2));
}
