.total {
  font-size: 2.2rem;
}

.dotted {
  margin-bottom: 0.3em;
  flex-grow: 1;
  background-image: linear-gradient(
    to right,
    var(--colorBlack60) 33%,
    var(--colorWhite) 0%
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}
