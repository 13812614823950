:root {
  --tabListHeaderHeight: 50px;
}

.base {
  flex-grow: 1;
  position: relative;
  background: var(--colorWhite);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  white-space: normal;
}

.heading {
  padding-bottom: 12px;
  padding-top: 12px;
}

.tablist {
  height: var(--tabListHeaderHeight);
  justify-content: start !important;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.07),
    0px 2px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
  background-color: white;
  position: sticky !important;
  top: 0;
  left: 0;
}
