.textWrapper {
  background: hsl(var(--colorGreenLightHsl) / 33%);
}

.imageWrapper {
  min-width: 100%;
}

.headerWrapper {
  margin-bottom: auto;
}

.deliveryImage {
  background: radial-gradient(
    150% 123% ellipse at center top,
    #006241 10%,
    #79bb6a 60%,
    #f1f8f5 60%
  );
}

@media (--breakpointLg) {
  .headerWrapper {
    margin-top: auto;
  }
  .textWrapper {
    background: var(--colorWhite);
  }
  .deliveryTextContent {
    width: var(--headerCrateProportion);
    order: 1;
    position: relative;
    height: 100%;
  }

  .deliveryImage {
    position: fixed;
    left: var(--headerCrateProportion);
    right: 0;
    top: var(--globalNavHeightDesktop);
    bottom: 0;
    background: radial-gradient(
      270% 91% ellipse at center top,
      #006241 5%,
      #79bb6a 60%,
      #f1f8f5 60%
    );
  }
}
