@keyframes filter-active-expansion {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.listItem {
  border-bottom: 1px solid var(--colorBlack10);
}

.filterButton {
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition:
    0.2s ease-out background-color,
    0.2s ease-out color;
}

.filterButton:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.filterButton:focus-visible {
  outline-offset: -4px;
}

.filterTypeIcon {
  margin-right: 18px;
  top: -1px;
  left: -2px;
}

.activeIcon {
  opacity: 0;
  transition: 0.2s ease-out opacity;
}

.isActive .activeIcon {
  /*
   * CSS Modules doesn't handle shorthand `animation` properties with scoped animation names
   * well, causing the rendered animation name to be incorrect when used in shorthand.
   */
  animation-name: filter-active-expansion;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.32, 2.32, 0.61, 0.27);
  animation-fill-mode: forwards;
  opacity: 1;
}
