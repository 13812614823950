@import '../root/vars.css';

.base {
  flex-shrink: 0;
  /* wraps the controls and filter list */
  position: relative;
  /* layering for shadow and to sit above map */
  z-index: 2;
  /* fixes collapsing container height in Safari */
  min-height: 50px;
  background-color: var(--colorWhite);
}

@media (--breakpointCrateLayout) {
  .base {
    box-shadow: none;
  }
}
