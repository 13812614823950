.buttonGroup {
  height: 48px;
  width: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  transition: all 100ms ease-out;
}

.button {
  color: var(--colorTextBlackSoft);
  position: relative;
  height: 24px;
  width: 24px;
  background: white;
  transition: color 200ms ease-out;
}

.zoomOut {
  composes: button;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.zoomIn {
  composes: button;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.button:hover {
  color: var(--colorTextBlack);
}

.button:focus-visible {
  outline: none;
  background: var(--colorGreenStarbucks);
  color: var(--colorTextWhite);
}
