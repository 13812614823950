.root {
  padding-top: var(--space-3);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.message {
  min-height: 5rem;
}

@media (--breakpointLg) {
  .root {
    max-width: 500px;
  }
}
