@import './vars.css';

.base {
  flex-direction: column;
  /* below global header */
  z-index: 1;
}

.locationsContainer {
  /* flex child of Root element */
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  z-index: 1; /* above the map */
  word-wrap: normal !important; /* override inherited value from ContentCrate */
  background-color: var(--colorWhite);
  box-shadow: var(--sectionBoxShadowReverse);
}

.globalNavOffset {
  top: var(--globalNavHeightMobile);
}

@media (--breakpointMd) {
  .globalNavOffset {
    top: var(--globalNavHeightTablet);
  }
}

@media (--breakpointLg) {
  .base {
    flex-direction: row;
  }

  .mapContainer {
    flex-basis: var(--contentCrateProportion);
    position: fixed;
    right: 0;
    top: var(--globalNavHeightDesktop);
    bottom: 0;
    width: var(--contentCrateProportion);
  }

  .locationsContainer {
    flex-grow: 0;
    flex-basis: var(--headerCrateProportion);
    box-shadow: var(--sectionBoxShadow);
  }

  .globalNavOffset {
    top: var(--globalNavHeightDesktop);
  }

  .deliveryToggle {
    width: var(--headerCrateProportion);
  }

  .locatorControls {
    width: var(--headerCrateProportion);
  }
}

@media (--breakpointLgMax) {
  .overlayShadow {
    /* provide a top shadow for the store details overlay 
     * and the filter options overlay
     */
    box-shadow: inset 0 4px 5px -3px rgba(0, 0, 0, 0.2);
  }
}
