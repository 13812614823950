.base {
  /* flex properties allow child to have 100% of available vertical space */
  display: flex;
  flex-direction: column;
  width: calc(100% - 4px); /* maintain width of flex parent */
  background-color: var(--colorWhite);
  font-size: 1.3rem;
  /* Re-enable pointer events disabled by `.locationCardList` */
  pointer-events: all;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  transition-property: background-color;
  padding-right: var(--space-3);
  margin: 2px;
}

.base:not(:global(.isActive)):hover {
  background-color: var(--colorBlack6);
}
