.caret {
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
}

.expanded {
  transform: rotate(180deg);
}

.borderRadius {
  border-radius: 4px;
}
