.partyPopper {
  min-width: 80px !important;
  max-width: 80px !important;
}

@media (--breakpointSmMax) {
  .partyPopper {
    min-width: 72px !important;
    max-width: 72px !important;
  }
}
