.reset {
  top: -1rem;
  right: var(--space-9);
}

@media (--breakpointSmMax) {
  .reset {
    top: -2rem;
    right: var(--space-3);
  }
}
