@import '../root/vars.css';

.form {
  display: flex;
  position: relative;
}

.input {
  border: 0;
  display: block;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: solid 1px var(--colorTextBlackSoft);
}

.buttonContainer {
  display: block;
  width: var(--locatorControlHeight);
  height: 100%;
  position: absolute;
  right: 0px;
}

.button {
  color: var(--colorTextBlackSoft);
  transition: color 200ms ease-out;
  width: 100%;
  height: 100%;
}

.button:hover {
  color: var(--colorTextBlack);
}

.icon {
  position: relative;
  left: 3px;
}
